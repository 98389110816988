import { GoalName } from '../__generated-gql-types__/globalTypes';

import { isServer } from './constants';

const DEFAULT_LOCALE = 'en-US';

const truncateNamePart = (namePart: string): string => {
  const firstLetter = namePart[0];
  return firstLetter ? `${firstLetter}.` : '';
};

export const formatName = ({
  firstName,
  lastName,
  shouldTruncateLastName = true,
}: {
  firstName: string;
  lastName: string;
  shouldTruncateLastName?: boolean;
}): string => {
  const formattedLastName = shouldTruncateLastName
    ? truncateNamePart(lastName)
    : lastName;

  return `${firstName} ${formattedLastName}`.trim();
};

const MAXIMUM_FRACTION_DIGITS_FOR_PRICE = 2;
const MINIMUM_FRACTION_DIGITS_FOR_PRICE = 2;

/**
 * Format price with both currency and amount
 * @param {number} amount integer value in cents
 * @param {string} currency default to usd
 * @returns formatted price, like '$5.3'
 */
export const formatPrice = ({
  amount,
  currency = 'USD',
  maximumFractionDigits = MAXIMUM_FRACTION_DIGITS_FOR_PRICE,
  minimumFractionDigits = MINIMUM_FRACTION_DIGITS_FOR_PRICE,
  removeFractionDigitsForZeroCents = true,
}: {
  amount: Possible<number>;
  currency?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  removeFractionDigitsForZeroCents?: boolean;
}): string => {
  let locale = DEFAULT_LOCALE;

  // TODO: Migrate to use the next locale (maybe this abstracts the server and client)
  if (!isServer && window?.navigator?.language != null) {
    locale = window.navigator.language;
  }

  const amountInDollars = (amount ?? 0) / 100;
  const cents = amountInDollars - Math.floor(amountInDollars);
  const centsExist = cents > 0;

  const formatterOptions: Intl.NumberFormatOptions = {
    currency,
    style: 'currency',
    maximumFractionDigits: maximumFractionDigits, // Need both to support SSR
    minimumFractionDigits:
      !centsExist && removeFractionDigitsForZeroCents
        ? 0
        : minimumFractionDigits, // Need both to support SSR
  };

  const formatter = new Intl.NumberFormat(locale, formatterOptions);

  if (amount == null) {
    return formatter.format(NaN);
  }

  return formatter.format(amount / 100);
};

/**
 * Format rate to include zeros
 * @param {number} rate integer value
 * @returns formatted rate, like '5.00'
 */
export const formatRate = (
  rate: number,
  minimumFractionDigits = 2,
  maximumFractionDigits = minimumFractionDigits,
): string => {
  let locale = DEFAULT_LOCALE;

  // TODO: Migrate to use the next locale (maybe this abstracts the server and client)
  if (!isServer && window?.navigator?.language != null) {
    locale = window.navigator.language;
  }

  const numWithZeroes = rate.toLocaleString(locale, {
    useGrouping: false,
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return numWithZeroes;
};

const GOAL_NAME_COACHES_LABELS: { [key in GoalName]: string } = Object.freeze({
  [GoalName.CAREER]: '',
  [GoalName.SCHOOL]: 'Admissions ',
  [GoalName.TEST]: 'Test Prep ',
});

export const formatGoalCoachesLabel = (goal?: GoalName): string => {
  return `${goal ? GOAL_NAME_COACHES_LABELS[goal] : ''}Coaches`;
};
